import React, { type SyntheticEvent } from 'react';
import AddIconOld from '@atlaskit/icon/glyph/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { Box, xcss, Inline, Pressable } from '@atlaskit/primitives';
import Text from '@atlaskit/primitives/text';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	onClick: (event: SyntheticEvent) => void;
};

const AddStatusIcon = () => (
	<Box xcss={iconWrapperStyles}>
		<AddIcon
			label=""
			LEGACY_fallbackIcon={AddIconOld}
			LEGACY_size="small"
			color={token('color.icon')}
		/>
	</Box>
);

const AddStatusButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Inline xcss={dividerStyles}>
			<Pressable
				onClick={onClick}
				padding="space.025"
				backgroundColor="color.background.neutral.subtle"
				xcss={buttonStyles}
			>
				<AddStatusIcon />
				<Text weight="medium" color="color.text.subtle">
					{formatMessage(messages.addStatus)}
				</Text>
			</Pressable>
		</Inline>
	);
};

export default AddStatusButton;

const ICON_SIZE = 24;

const iconWrapperStyles = xcss({
	height: `${ICON_SIZE}px`,
	width: `${ICON_SIZE}px`,
	borderRadius: 'border.radius',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral',
	marginLeft: 'space.025',
	marginRight: 'space.100',
});

const dividerStyles = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: '1px',
	paddingTop: 'space.100',
	marginTop: 'space.100',
	display: 'flex',
	width: '45%',
	alignSelf: 'flex-end',
});

const buttonStyles = xcss({
	color: 'color.text.subtle',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 'border.radius',
	borderWidth: '1px',
	marginBottom: 'space.100',
	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
});
