import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/src/hooks/useAnalyticsEvents';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import {
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { IssueTypeType } from '@atlassian/jira-business-summary-services/src/services/types';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { toFlagId } from '@atlassian/jira-flags';
import { showFlag } from '@atlassian/jira-flags/src/services';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { RENAME_ISSUE_TYPE_EXPERIENCE } from '../../common/constants';
import useFetchIssueLayoutConfiguration from '../../services/issue-layout-configuration';
import updateIssueType from '../../services/update-issue-type';
import messages from './messages';

export const useRenameIssueType = (issueType: IssueTypeType) => {
	const { formatMessage } = useIntl();
	const project = useProject();
	const fetchIssueLayoutConfiguration = useFetchIssueLayoutConfiguration(project.id, issueType.id);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const startExperience = useExperienceStart(RENAME_ISSUE_TYPE_EXPERIENCE);
	const onExperienceSuccess = useExperienceSuccess(RENAME_ISSUE_TYPE_EXPERIENCE);
	const onExperienceFail = useExperienceFail(RENAME_ISSUE_TYPE_EXPERIENCE);

	return useCallback(
		async (name: string, refetchIssueTypes: () => void) => {
			try {
				startExperience();
				const issueLayoutConfiguration = await fetchIssueLayoutConfiguration();

				if (!issueLayoutConfiguration) {
					throw new Error(
						`cannot find issue layout configuration for project: ${project.id} and issue type: ${issueType.id}`,
					);
				}

				await updateIssueType(project.id, issueLayoutConfiguration.id, Number(issueType.id), {
					owners: [
						{
							data: {
								...issueLayoutConfiguration.owners,
								name,
								iconUrl: issueType.iconUrl,
								id: Number(issueType.id),
							},
							type: 'ISSUE_TYPE',
						},
					],
				});
				await refetchIssueTypes();

				onExperienceSuccess();
				fireTrackAnalytics(createAnalyticsEvent({}), 'issueType updated');
			} catch (saveIssueTypeError: unknown) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onExperienceFail('Failed to rename issue type', saveIssueTypeError as Error);
				fireErrorAnalytics({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: saveIssueTypeError as Error,
					meta: {
						id: 'business-summary-types-of-work-rename-error',
						packageName: 'jiraBusinessSummary',
					},
				});
				showFlag({
					type: 'error',
					title: formatMessage(messages.errorFlagTitleRenameIssueType),
					description: formatMessage(messages.issueTypeNameNetworkError),
					id: toFlagId('jwm-rename-issue-type-name-network-error.flag'),
				});
			}
		},
		[
			startExperience,
			fetchIssueLayoutConfiguration,
			project.id,
			issueType.id,
			issueType.iconUrl,
			createAnalyticsEvent,
			onExperienceSuccess,
			formatMessage,
			onExperienceFail,
		],
	);
};
