import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import type { SectionProps } from '../../../common/types';
import AccessibleLink from '../../../common/ui/accessible-link';
import Card from '../../../common/ui/card';
import CreateItemLink from '../../../common/ui/create-item-link';
import PercentageBarTable from '../../../common/ui/percentage-bar-table';
import PercentageBarRow from '../../../common/ui/percentage-bar-table/percentage-bar-row';
import { useSectionAnalytics } from '../../../common/utils';
import { useSummaryFeatures } from '../../../controllers/features-context';
import useIssuesByType from '../../../controllers/paged-issues-by-type';
import { AddIssueTypes } from './add-issue-types';
import IssueTypeRow from './issue-type-row';
import ManageTypesButton from './manage-types-button';
import { ICON_SIZE } from './manage-types-button/styled';
import messages from './messages';
import { CenteredSkeleton } from './skeleton/styled';

const TypesOfWork = ({ position }: SectionProps) => {
	const { formatMessage } = useIntl();
	const { data, loading, fetch, fetchNext, loadingNext } = useIssuesByType();
	const { hasCreateItem, hasManageTypesButton, linksTarget, urlBuilder } = useSummaryFeatures();

	// Intentionally not firing exposure here to avoid unnecessary exposure
	const [expConfig] = UNSAFE_noExposureExp('jwm_summary_page_inline_issue_types_change');
	const isInlineIssueTypesEnabled = expConfig.get('isInlineIssueTypesEnabled', false);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const project = isInlineIssueTypesEnabled ? useProject() : null;
	const isTmp: boolean = project?.isSimplified ?? false;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const locale = isInlineIssueTypesEnabled ? useLocale() : null;
	const isEnglishLocale = locale ? locale.includes('en') : false;

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'typesOfWork',
	);

	const issueTypeNames = data?.issueTypes.map(
		({ issueType: currentIssueType }) => currentIssueType.name,
	);

	let content;

	if (loading) {
		content = (
			<UFOLoadHold name="types-of-work">
				<CenteredSkeleton testId="business-summary.ui.summary-view.types-of-work.skeleton" />
			</UFOLoadHold>
		);
	} else {
		const headers = {
			identifier: formatMessage(messages.issueTypeColumnHeader),
			percentageBar: formatMessage(messages.issueDistributionColumnHeader),
			count: formatMessage(messages.countColumnHeader),
		};

		content = (
			<>
				<Description>
					{data?.totalUnresolvedIssues === 0 ? (
						<>
							{formatMessage(messages.descriptionWhenEmpty)}
							{hasCreateItem && (
								<CreateItemLink
									href={`${urlBuilder('typesOfWork')}?inlineCreate=true`}
									onClick={() =>
										fireSectionAnalytics('view', {
											inlineCreate: true,
										})
									}
									target={linksTarget}
								/>
							)}
						</>
					) : (
						<FormattedI18nMessage
							message={formatMessage(messages.description, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<AccessibleLink
										href={`${urlBuilder('typesOfWork')}?direction=ASC&sortBy=issuetype`}
										target={linksTarget}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: false,
											})
										}
										aria-label={formatMessage(messages.viewAllItemsLabel)}
									>
										{children}
									</AccessibleLink>
								),
							}}
						/>
					)}
				</Description>

				<PercentageBarTable
					loading={loadingNext}
					onScrollToEnd={fetchNext}
					headers={headers}
					label={formatMessage(messages.title)}
				>
					{data &&
						data.issueTypes.map(({ id, issueType, count }) =>
							isTmp &&
							hasManageTypesButton &&
							isEnglishLocale &&
							expVal(
								'jwm_summary_page_inline_issue_types_change',
								'isInlineIssueTypesEnabled',
								false,
							) ? (
								<IssueTypeRow
									key={id}
									count={count}
									totalCount={data.totalUnresolvedIssues}
									issueType={issueType}
									position={position}
									issueTypeNames={issueTypeNames}
									refetchIssueTypes={fetch}
								/>
							) : (
								<PercentageBarRow
									key={id}
									iconBefore={<IssueType src={issueType.iconUrl} alt="" />}
									itemCount={count}
									totalCount={data.totalUnresolvedIssues}
									linkUrl={`${urlBuilder('typesOfWork', true)}?filter=${encodeURIComponent(
										`type = '${issueType.name.split("'").join("\\'")}' AND ${OPEN_ISSUES_JQL}`,
									)}`}
									onRowLinkClick={() =>
										fireSectionAnalytics('viewWithFilter', {
											count,
										})
									}
									linkTarget={linksTarget}
									name={issueType.name}
								/>
							),
						)}
				</PercentageBarTable>

				{hasManageTypesButton &&
					(isTmp &&
					isEnglishLocale &&
					expVal(
						'jwm_summary_page_inline_issue_types_change',
						'isInlineIssueTypesEnabled',
						false,
					) ? (
						<AddIssueTypes
							onAddIssueTypesButtonClick={() => fireSectionAnalytics('addIssueTypes')}
							onIssueTypesHierarchyEducationButtonClick={() =>
								fireSectionAnalytics('tipsForHierarcyEducationIssueTypes')
							}
							onIssueTypeCreated={fetch}
							testId="business-summary.ui.summary-view.types-of-work.add-issue-types"
						/>
					) : (
						<ManageTypesButton onClick={() => fireSectionAnalytics('manageTypes')} />
					))}
			</>
		);
	}

	return (
		<UFOSegment name="business-summary-types-of-work">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.types-of-work"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(TypesOfWork);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueType = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${ICON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${ICON_SIZE}px`,
	borderRadius: token('border.radius', '4px'),
});
