import React, { useCallback, useState, useMemo } from 'react';
import EditIcon from '@atlaskit/icon/glyph/edit';
import Image from '@atlaskit/image';
import { Box, Inline, Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants';
import type { IssueTypeType } from '@atlassian/jira-business-summary-services/src/services/types';
import { showFlag } from '@atlassian/jira-flags/src/services';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ISSUE_TYPE_MAX_LENGTH } from '../../../../common/constants';
import InlineRenameField from '../../../../common/ui/inline-rename-field';
import PercentageBarRow from '../../../../common/ui/percentage-bar-table/percentage-bar-row';
import { getCleanIssueTypesName, useSectionAnalytics } from '../../../../common/utils';
import { useSummaryFeatures } from '../../../../controllers/features-context';
import { useRenameIssueType } from '../../../../controllers/rename-issue-types';
import { ICON_SIZE } from '../manage-types-button/styled';
import messages from './messages';
import { getInvalidIssueTypeNameContent } from './utils';

const IssueTypeRow = ({
	count,
	totalCount,
	issueType,
	position,
	issueTypeNames,
	refetchIssueTypes,
}: {
	count: number;
	totalCount: number;
	issueType: IssueTypeType;
	position: number;
	issueTypeNames?: string[];
	refetchIssueTypes: () => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const { linksTarget, urlBuilder } = useSummaryFeatures();
	const { fireSectionAnalytics } = useSectionAnalytics(position, 'typesOfWork');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [optimisticallyUpdatedName, setOptimisticallyUpdatedName] = useState('');
	const { formatMessage } = useIntl();

	const issueTypeFilteredListViewUrl = useCallback(
		(issueTypeName: string): string =>
			`${urlBuilder('typesOfWork', true)}?filter=${encodeURIComponent(
				`type = '${issueTypeName.split("'").join("\\'")}' AND ${OPEN_ISSUES_JQL}`,
			)}`,
		[urlBuilder],
	);

	const renameIssueType = useRenameIssueType(issueType);

	const upperCaseIssueTypeNames = useMemo(
		() => issueTypeNames?.map((name) => name.toUpperCase()),
		[issueTypeNames],
	);

	const validateIssueTypeName = useCallback(
		(newName: string) =>
			getInvalidIssueTypeNameContent({
				newName,
				oldName: issueType.name,
				issueTypeNameList: upperCaseIssueTypeNames,
				formatMessage,
			})?.inline,
		[issueType.name, upperCaseIssueTypeNames, formatMessage],
	);

	const saveIssueTypeName = useCallback(
		async (name: string) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'edit submitted', 'inlineEditIssueTypes', {});

			setIsEditing(false);

			if (!name) {
				return undefined;
			}

			if (name === issueType.name) {
				return undefined;
			}

			const cleanedName = getCleanIssueTypesName(name);

			const invalidIssueTypeFlag = getInvalidIssueTypeNameContent({
				newName: name,
				oldName: issueType.name,
				issueTypeNameList: upperCaseIssueTypeNames,
				formatMessage,
			})?.flag;

			if (invalidIssueTypeFlag) {
				showFlag(invalidIssueTypeFlag);
				return;
			}

			setOptimisticallyUpdatedName(cleanedName);
			await renameIssueType(cleanedName, refetchIssueTypes);
			setOptimisticallyUpdatedName('');
		},
		[
			issueType.name,
			upperCaseIssueTypeNames,
			formatMessage,
			renameIssueType,
			refetchIssueTypes,
			createAnalyticsEvent,
		],
	);

	return (
		<PercentageBarRow
			iconBefore={
				<Inline xcss={IssueTypeIconStyles}>
					<Image
						src={issueType.iconUrl}
						alt=""
						height={`${ICON_SIZE}px`}
						width={`${ICON_SIZE}px`}
					/>
				</Inline>
			}
			itemCount={count}
			totalCount={totalCount}
			linkUrl={issueTypeFilteredListViewUrl(issueType.name)}
			onRowLinkClick={() =>
				fireSectionAnalytics('viewWithFilter', {
					count,
				})
			}
			linkTarget={linksTarget}
			name={!isEditing ? optimisticallyUpdatedName || issueType.name : undefined}
			actions={
				<>
					{isEditing ? (
						<Box paddingBlockEnd="space.100">
							<InlineRenameField
								defaultValue={issueType.name}
								isEditing={isEditing}
								readView={() => <></>}
								onCancel={() => setIsEditing(false)}
								onConfirm={(updatedName: string) => saveIssueTypeName(updatedName)}
								onEdit={() => setIsEditing(true)}
								onValidate={validateIssueTypeName}
								maxLength={ISSUE_TYPE_MAX_LENGTH}
							/>
						</Box>
					) : (
						<Tooltip content="" position="bottom">
							<Pressable
								xcss={iconButtonStyles}
								onClick={() => {
									setIsEditing(true);
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'button clicked',
										'inlineEditIssueTypes',
										{},
									);
								}}
								aria-label={formatMessage(messages.editTitle)}
								testId="business-summary.ui.summary-view.types-of-work.issue-type-row.edit"
							>
								<EditIcon size="medium" label={formatMessage(messages.editTitle)} />
							</Pressable>
						</Tooltip>
					)}
				</>
			}
		/>
	);
};

const iconButtonStyles = xcss({
	borderRadius: 'border.radius',
	outlineOffset: 'space.0',
	padding: 'space.050',
	backgroundColor: 'color.background.accent.gray.subtler',
});

const IssueTypeIconStyles = xcss({
	borderRadius: 'border.radius.050',
	flex: 'none',
});

export default IssueTypeRow;
