import { toFlagId, type Flag } from '@atlassian/jira-flags';
import type { IntlShape } from '@atlassian/jira-intl';
import { ISSUE_TYPE_MAX_LENGTH } from '../../../../common/constants';
import messages from './messages';

export const getInvalidIssueTypeNameContent = ({
	newName,
	oldName,
	issueTypeNameList,
	formatMessage,
}: {
	newName: string;
	oldName: string;
	issueTypeNameList?: string[];
	formatMessage: IntlShape['formatMessage'];
}):
	| {
			flag: Flag;
			inline: string;
	  }
	| undefined => {
	if (!newName) {
		return undefined;
	}

	if (newName === oldName) {
		return undefined;
	}

	if (newName.length > ISSUE_TYPE_MAX_LENGTH) {
		return {
			flag: {
				type: 'error',
				title: formatMessage(messages.errorFlagTitleRenameIssueType),
				description: formatMessage(messages.issueTypeNameTooLongError, {
					maxLength: ISSUE_TYPE_MAX_LENGTH,
				}),
				id: toFlagId('jwm-rename-issue-type-name-is-not-unique.flag'),
			},
			inline: formatMessage(messages.issueTypeNameTooLongError, {
				maxLength: ISSUE_TYPE_MAX_LENGTH,
			}),
		};
	}

	if (issueTypeNameList?.includes(newName.toUpperCase())) {
		return {
			flag: {
				type: 'error',
				title: formatMessage(messages.errorFlagTitleRenameIssueType),
				description: formatMessage(messages.issueTypeNameAlreadyInUse, { newName }),
				id: toFlagId('jwm-rename-issue-type-name-is-not-unique.flag'),
			},
			inline: formatMessage(messages.issueTypeNameAlreadyInUse, { newName }),
		};
	}

	return undefined;
};
