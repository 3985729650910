import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitleRenameIssueType: {
		id: 'business-summary.summary-view.types-of-work.issue-type-row.error-flag-title-rename-issue-type',
		defaultMessage: 'Unable to rename',
		description: 'Title of the error flag shown to a user if rename issue type is unsuccessful',
	},
	issueTypeNameTooLongError: {
		id: 'business-summary.summary-view.types-of-work.issue-type-row.issue-type-name-too-long-error',
		defaultMessage:
			'Maximum {maxLength, plural, one {# character} other {# characters}}. Please try again.',
		description:
			'Error message to be shown to user when user tries to rename issue type into name that is too long',
	},
	issueTypeNameAlreadyInUse: {
		id: 'business-summary.summary-view.types-of-work.issue-type-row.issue-type-name-already-in-use',
		defaultMessage: 'Issue type already exists. Please try again.',
		description:
			'Error message to be shown to user when user tries to rename issue type into name that is already in use',
	},
	editTitle: {
		id: 'business-summary.summary-view.types-of-work.issue-type-row.edit-title',
		defaultMessage: 'Edit',
		description: 'label for edit buttons',
	},
});
