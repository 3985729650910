import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

const PACKAGE_NAME = 'jiraBusinessSummary';

export const CARD_MIN_WIDTH = 420;
export const CARD_MARGIN = gridSize;
export const CARD_BORDER_RADIUS = 8;
export const ISSUE_TYPE_MAX_LENGTH = 60;
export const LARGE_SCREEN_WIDTH = 1768;

export const MAIN_EXPERIENCE: ExperienceDetails = {
	experience: 'summary',
	experienceId: 'main',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const ACTIVITY_FEED_EXPERIENCE: ExperienceDetails = {
	experience: 'activity-feed',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const OVERVIEW_DETAILS_EXPERIENCE: ExperienceDetails = {
	experience: 'overview-details',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const PROJECT_DETAILS_EXPERIENCE: ExperienceDetails = {
	experience: 'project-details',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
export const RELATED_PROJECTS_EXPERIENCE: ExperienceDetails = {
	experience: 'related-projects',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};

export const RENAME_ISSUE_TYPE_EXPERIENCE: ExperienceDetails = {
	experience: 'rename-issue-type',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};
