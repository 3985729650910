import React, { memo, type SyntheticEvent, useMemo, useCallback } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { Box, Pressable, xcss, Flex } from '@atlaskit/primitives';
import Text from '@atlaskit/primitives/text';
import { token } from '@atlaskit/tokens';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import type { Fetch } from '@atlassian/jira-business-pagination';
import type { IssuesByType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import { asyncCreateIssueTypeModalEntryPoint } from '@atlassian/jira-create-issue-type-modal/entrypoint';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages';

export type AddIssueTypesButtonProps = {
	onClick: (event: SyntheticEvent) => void;
	onIssueTypeCreated: Fetch<IssuesByType>;
};

const AddIssueTypesButton = ({ onClick, onIssueTypeCreated }: AddIssueTypesButtonProps) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { key: projectKey } = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleIssueTypeCreated = useCallback(() => {
		onIssueTypeCreated();
	}, [onIssueTypeCreated]);

	const onCloseCreateIssueTypesModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'createIssueTypesModal',
				action: 'dismissed',
			}),
			'createIssueTypesModalDismissed',
		);
	}, [createAnalyticsEvent]);

	const entryPointProps = useMemo(
		() => ({
			onIssueTypeCreated: handleIssueTypeCreated,
			issueTypesCountLimit: 30,
			onClose: onCloseCreateIssueTypesModal,
		}),
		[handleIssueTypeCreated, onCloseCreateIssueTypesModal],
	);

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			projectKey,
		}),
		[cloudId, projectKey],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncCreateIssueTypeModalEntryPoint}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			interactionName="open-create-issue-type-modal"
			modalProps={{ width: 424 }}
			useInternalModal
		>
			{({ ref }) => (
				<Box ref={ref}>
					<Pressable
						onClick={onClick}
						backgroundColor="color.background.neutral.subtle"
						xcss={buttonStyles}
					>
						<Flex xcss={addIconWrapperStyles} alignItems="center" justifyContent="center">
							<AddIcon label="" color={token('color.icon')} />
						</Flex>
						<Text weight="medium" color="color.text.subtle">
							{formatMessage(messages.addIssueTypes)}
						</Text>
					</Pressable>
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export default memo<AddIssueTypesButtonProps>(AddIssueTypesButton);

const addIconWrapperStyles = xcss({
	height: '24px',
	width: '24px',
	borderRadius: 'border.radius',
	backgroundColor: 'color.background.neutral',
});

const buttonStyles = xcss({
	padding: 'space.025',
	color: 'color.text.subtlest',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.075',
	justifyContent: 'center',
	':hover': {
		textDecoration: 'underline',
	},
});
