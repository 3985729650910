import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { performPutRequest } from '@atlassian/jira-fetch';
import type {
	UpdateIssueTypeRequest,
	UpdateIssueLayoutApiResponse,
} from '@atlassian/jira-project-settings-apps-issue-type-page-product-features';

const updateIssueType = (
	projectId: number,
	layoutId: string,
	extraDefinerId: number,
	{ owners }: Pick<UpdateIssueTypeRequest, 'owners'>,
): Promise<Omit<UpdateIssueLayoutApiResponse, 'issueLayoutConfig'>> =>
	performPutRequest(`/rest/internal/1.0/issueLayouts/${layoutId}`, {
		body: JSON.stringify({
			projectId,
			extraDefinerId,
			owners,
			issueLayoutType: 'ISSUE_VIEW',
		}),
	}).catch((restError: Error) => {
		fireErrorAnalytics({
			meta: {
				id: 'UpdateIssueType',
				packageName: 'jiraBusinessSummary',
				teamName: 'wanjel',
			},
			error: restError,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw restError;
	});

export default updateIssueType;
