import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import { useSummaryData } from '@atlassian/jira-router-resources-business-summary-data';
import type {
	SummaryContextType,
	OverviewItemsType,
	IssuesByStatusType,
	IssuesByPriorityType,
	IssuesByAssigneeType,
	IssuesByTypeType,
	ProjectIssueTypesCount,
	WorkflowsCount,
} from './types';

type SummaryProviderProps = {
	children: ReactNode;
};

const SummaryContext = createContext<SummaryContextType>({
	data: undefined,
	loading: true,
	error: undefined,
});

export const PrefetchedSummaryProvider = ({ children }: SummaryProviderProps) => {
	const { data, loading, error } = useSummaryData();

	const context = useMemo(
		() => ({
			data,
			// we want to show loading state for server side rendering
			loading: __SERVER__ ? true : loading,
			error,
		}),
		[data, loading, error],
	);

	return <SummaryContext.Provider value={context}>{children}</SummaryContext.Provider>;
};

export const useSummaryContext = (): SummaryContextType => useContext(SummaryContext);

export const useOverviewItems = (): OverviewItemsType => {
	const { data, loading, error } = useSummaryContext();

	return { data: data?.overviewItems, loading, error };
};

export const useIssuesByStatus = (): IssuesByStatusType => {
	const { data, loading, error } = useSummaryContext();

	return { data: data?.issuesByStatus, loading, error };
};

export const useIssuesByPriority = (): IssuesByPriorityType => {
	const { data, loading, error } = useSummaryContext();

	return { data: data?.issuesByPriority, loading, error };
};

export const useIssuesByAssignee = (): IssuesByAssigneeType => {
	const { data, loading, error } = useSummaryContext();

	return { data: data?.issuesByAssignee, loading, error };
};

export const useIssuesByType = (): IssuesByTypeType => {
	const { data, loading, error } = useSummaryContext();

	return { data: data?.issuesByType, loading, error };
};

export const useProjectIssueTypesCount = (): ProjectIssueTypesCount => {
	const { data, loading, error } = useSummaryContext();

	return { data: { totalCount: data?.projectIssueTypesCount }, loading, error };
};

export const useWorkflowsCount = (): WorkflowsCount => {
	const { data, loading, error } = useSummaryContext();

	return { data: { totalCount: data?.worklowsCount }, loading, error };
};
