import React, { memo, useCallback, type SyntheticEvent, useEffect } from 'react';
import Button from '@atlaskit/button';
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team';
import { N100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { INVITE_OPEN_PARAM } from '@atlassian/jira-business-constants';
import { useAddPeople } from '@atlassian/jira-business-invite-people/src/controllers/add-people-provider/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryParam } from '@atlassian/react-resource-router';
import { useSummaryFeatures } from '../../../../../controllers/features-context';
import messages from './messages';
import { InviteTeammateIconContainer, InviteTeammateButtonContainer } from './styled';

type Props = {
	onClick: (arg1: SyntheticEvent) => void;
};

const InviteTeammateButton = ({ onClick }: Props) => {
	const { openAddPeopleModal } = useAddPeople();
	const { formatMessage } = useIntl();
	const [inviteOpenParam, setInviteOpenParam] = useQueryParam(INVITE_OPEN_PARAM);
	const hasInviteOpenParam = inviteOpenParam === 'true';
	const { linksTarget } = useSummaryFeatures();

	const handleClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
		(event) => {
			openAddPeopleModal();
			onClick(event);
		},
		[onClick, openAddPeopleModal],
	);

	useEffect(() => {
		if (hasInviteOpenParam) {
			openAddPeopleModal(() => {
				setInviteOpenParam(undefined);
			});
		}
	}, [hasInviteOpenParam, openAddPeopleModal, setInviteOpenParam]);

	const inviteTeammateIcon = (
		<InviteTeammateIconContainer>
			<InviteTeamIcon label="" size="small" primaryColor={token('color.icon.subtle', N100)} />
		</InviteTeammateIconContainer>
	);

	const buttonProps =
		linksTarget === '_blank'
			? {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					href: `${window.location.href.replace('/embed', '')}?${INVITE_OPEN_PARAM}=true`,
					target: '_blank',
				}
			: { onClick: handleClick };

	return (
		<InviteTeammateButtonContainer>
			<Button
				{...buttonProps}
				iconBefore={inviteTeammateIcon}
				appearance="subtle-link"
				testId="business-summary.ui.summary-view.workload-by-assignee.invite-teammate.invite-teammate-button"
			>
				{formatMessage(messages.inviteTeammateButton)}
			</Button>
		</InviteTeammateButtonContainer>
	);
};

export default memo<Props>(InviteTeammateButton);
