import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldNameButtonLabel: {
		id: 'business-summary.common.inline-rename-field.field-name-button-label',
		defaultMessage: 'Status {fieldName}',
		description:
			'Label (used by screen readers) of the field name button that can be clicked to edit the field name',
	},
});
