import React, { memo, useMemo, type ComponentType } from 'react';
import '@atlaskit/css-reset';
import ProjectContextProvider from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/index.tsx';
import SummaryView from '@atlassian/jira-business-summary';
import { projectUrlBuilderFactory } from '@atlassian/jira-business-summary/src/common/utils.tsx';
import FeaturesProvider from '@atlassian/jira-business-summary/src/controllers/features-context/index.tsx';
import { useProject } from '@atlassian/jira-router-resources-business-project-details';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication';
import UFOSegment from '@atlassian/jira-ufo-segment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JiraBusinessSummaryAppType = Record<any, any>;

const JiraBusinessSummaryApp: ComponentType<JiraBusinessSummaryAppType> = () => {
	const { data: project } = useProject();

	const urlBuilder = useMemo(() => projectUrlBuilderFactory(project?.key ?? ''), [project]);

	const hasAdministerProjectPermission = Boolean(project?.permissions.administerProject);
	const hasCreateItem = Boolean(project?.permissions.createIssues);

	return (
		<UFOSegment name="jira-business-summary-app">
			<AuthenticationCheck>
				<ProjectContextProvider>
					<FeaturesProvider
						hasCreateItem={hasCreateItem}
						hasInviteTeammateButton={hasAdministerProjectPermission}
						hasManageTypesButton={hasAdministerProjectPermission}
						urlBuilder={urlBuilder}
					>
						<SummaryView />
					</FeaturesProvider>
				</ProjectContextProvider>
				<SubProductUpdater subProduct="core" />
			</AuthenticationCheck>
		</UFOSegment>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(JiraBusinessSummaryApp);
