import React, { memo } from 'react';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { MAIN_EXPERIENCE } from './common/constants';
import { PrefetchedSummaryProvider } from './controllers/summary-context';
import SummaryView from './ui/summary-view/index.tsx';

const Summary = () => {
	const view = <SummaryView />;

	return (
		<ExperienceErrorBoundary experience={MAIN_EXPERIENCE} shouldStartExperience>
			<BusinessAppWrapper withOverflow>
				<ContextualAnalyticsData objectType="summary" sourceType={SCREEN} sourceName="summary">
					<PrefetchedSummaryProvider>{view}</PrefetchedSummaryProvider>
					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			</BusinessAppWrapper>
		</ExperienceErrorBoundary>
	);
};

export default memo(Summary);
