/** @jsx jsx */
import { type ReactNode, useState, useMemo } from 'react';
import { styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector/src';
import { CARD_MARGIN } from '../../constants';

const SMALL_WIDTH = 924;
const MEDIUM_WIDTH = 1440;

type Props = {
	children: ReactNode;
};

const ResponsiveGrid = ({ children }: Props) => {
	const [width, setWidth] = useState<number | undefined>(undefined);

	const columnCount = useMemo(() => {
		if (width === undefined) {
			return undefined;
		}
		if (width < SMALL_WIDTH) {
			return 1;
		}
		if (width < MEDIUM_WIDTH) {
			return 2;
		}
		return 3;
	}, [width]);

	return (
		<Container columnCount={columnCount}>
			<WidthObserver setWidth={setWidth} />
			{columnCount && children}
		</Container>
	);
};
export default ResponsiveGrid;

type ContainerProps = {
	columnCount: number | undefined;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values,@atlaskit/ui-styling-standard/no-unsafe-values
	marginTop: `${CARD_MARGIN}px`,
	justifyContent: 'center',
	display: 'grid',
	width: '100%',
	gap: token('space.200', '1rem'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	gridTemplateColumns: ({ columnCount }: ContainerProps) => `repeat(${columnCount}, 1fr)`,
});
