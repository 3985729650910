import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import AddIssueTypesButton, { type AddIssueTypesButtonProps } from './add-issue-types-button';
import { IssueTypesHierarchyEducationButton } from './issue-types-hierarchy-education-button';

export type AddIssueTypesProps = {
	onAddIssueTypesButtonClick: () => void;
	onIssueTypesHierarchyEducationButtonClick: () => void;
	testId?: string;
	onIssueTypeCreated: AddIssueTypesButtonProps['onIssueTypeCreated'];
};

export const AddIssueTypes = ({
	onAddIssueTypesButtonClick,
	onIssueTypesHierarchyEducationButtonClick,
	onIssueTypeCreated,
	testId,
}: AddIssueTypesProps) => {
	return (
		<Inline space="space.200" testId={testId} xcss={addIssueTypesStyles}>
			<AddIssueTypesButton
				onClick={onAddIssueTypesButtonClick}
				onIssueTypeCreated={onIssueTypeCreated}
			/>
			<IssueTypesHierarchyEducationButton onClick={onIssueTypesHierarchyEducationButtonClick} />
		</Inline>
	);
};

const addIssueTypesStyles = xcss({
	paddingBlock: 'space.100',
});
